import getMatches from "../components/modules/futbalnet/matches";
import getTable from "../components/modules/futbalnet/table";
import { secondsFromTimeString } from "./common";
import { preprocessData } from "./preprocessData";

export const prepareBlockCss = async (blockData) => {
  // pripravit css z parametrov blocku
  let params = {};
  const fields = Object.entries(blockData?.css?.mapValue?.fields);
  if (fields.length > 0) {
    for (const [key, value] of fields) {
      params[key] = await preprocessData(value);
    }
  }
  return params;
};

export const prepareBlockModules = async (blockData) => {
  // pripravit css z parametrov blocku
  let params = {};
  let modules = {};
  if (Object.hasOwnProperty.call(blockData?.modules, "arrayValue")) {
    modules = Object.entries(blockData?.modules?.arrayValue?.values);
  } else {
    modules = Object.entries(blockData?.modules?.mapValue?.fields);
  }
  if (modules.length > 0) {
    for (const [key, fieldValue] of modules) {
      const settings = Object.entries(
        fieldValue.mapValue.fields.content.mapValue.fields
      );
      const order =
        fieldValue.mapValue.fields.module_settings.mapValue.fields.moduleOrder
          .integerValue;
      const durationString =
        fieldValue.mapValue.fields.module_settings.mapValue.fields.duration
          .stringValue;
      const duration = secondsFromTimeString(durationString);
      console.log(" duration: " + duration);
      let module = {};
      for (const [settingsKey, settingsValue] of settings) {
        switch (settingsKey) {
          case "dataType":
            module.dataType = settingsValue.stringValue;
            break;
          case "html":
            if (Object.hasOwnProperty.call(settingsValue, "arrayValue")) {
              module.html = settingsValue.arrayValue.values;
            } else {
              module.html = settingsValue.stringValue;
            }
            break;
          case "specialData":
            module.specialData = settingsValue.arrayValue.values;
            break;
          case "moduleParametersString":
            module.specialData = settingsValue.stringValue;
            break;
          case "status":
            module.status = settingsValue.integerValue;
            break;
          case "code":
            module.code = settingsValue.stringValue;
            break;
          case "parameters":
            const parameters = settingsValue?.mapValue?.fields;
            let params = {};
            if (parameters) {
              for (const [parameterKey, parameterValue] of Object.entries(
                parameters
              )) {
                let paramsArray = [];
                switch (parameterKey) {
                  case "moduleCss":
                  case "rssData":
                  case "events":
                    if (
                      Object.hasOwnProperty.call(parameterValue, "mapValue")
                    ) {
                      paramsArray = Object.entries(
                        parameterValue.mapValue.fields
                      );
                    } else if (
                      Object.hasOwnProperty.call(parameterValue, "arrayValue")
                    ) {
                      paramsArray = Object.entries(
                        parameterValue.arrayValue.values
                      );
                    }

                    if (paramsArray.length > 0) {
                      let arrayData = {};
                      for (const [key, value] of paramsArray) {
                        arrayData[key] = await preprocessData(value);
                      }
                      params[parameterKey] = arrayData;
                    }
                    break;
                  case "images":
                    paramsArray = Object.entries(
                      parameterValue.arrayValue.values
                    );
                    if (paramsArray.length > 0) {
                      let arrayData = {};
                      for (const [key, value] of paramsArray) {
                        const path = value.mapValue?.fields?.path?.stringValue;
                        const styleData =
                          value?.mapValue?.fields?.style?.mapValue?.fields;
                        const style = {
                          height: styleData?.height?.stringValue,
                          width: styleData?.width?.stringValue,
                        };
                        arrayData[key] = {
                          path,
                          style,
                        };
                      }
                      params[parameterKey] = arrayData;
                    }
                    break;
                  case "slides":
                    paramsArray = Object.entries(
                      parameterValue.arrayValue.values
                    );
                    if (paramsArray.length > 0) {
                      let arrayData = {};
                      for (const [key, value] of paramsArray) {
                        arrayData[key] = value.stringValue;
                      }
                      params[parameterKey] = arrayData;
                    }
                    break;
                  default:
                    if (parameterValue) {
                      params[parameterKey] = await preprocessData(
                        parameterValue
                      );
                    }
                }
              }
            }
            module.parameters = params;
            break;
          default:
            module.defualt = "";
        }
        module.order = Number(order);
        module.duration = duration;
      }
      // futbalnet
      if (module.code === "futbalnet") {
        const html = JSON.parse(module.html);
        switch (html.type) {
          case "1":
            module["html"] = await getTable(html.futbalnetId);
            break;
          default:
            module["html"] = await getMatches(html.futbalnetId);
        }
      }
      params[key] = module;
    }
  }
  const sortedParams = sortByPosition(params);
  return sortedParams;
};

const sortByPosition = (obj) => {
  const order = [],
    res = {};
  Object.keys(obj).forEach((key) => {
    return (order[obj[key]["order"]] = key);
  });
  let counter = 0;
  order.forEach((key) => {
    counter++;
    res[counter] = obj[key];
  });
  return res;
};

export const test = async (blockData) => {
  let params = {};
  let settings = [];
  for (const [key1, value1] of settings) {
    if (key1 === "moduleSettings") {
      let moduleSettings = {};
      for (const [key2, value2] of Object.entries(value1.mapValue.fields)) {
        if (key2 === "moduleCss") {
          let moduleCss = {};
          for (const [key3, value3] of Object.entries(value2.mapValue.fields)) {
            moduleCss[key3] = await preprocessData(value3);
          }
          moduleSettings[key2] = moduleCss;
        } else {
          moduleSettings[key2] = await preprocessData(value2);
        }
      }
      params[key1] = moduleSettings;
    } else {
      params[key1] = await preprocessData(value1);
    }
  }
};
