export const GetCanteenMenuByType = (
  type = 1,
  data,
  todayDate,
  tomorrowDate,
  todayDayName,
  tomorrowDayName
) => {
  return type === "1"
    ? menuFirstType(data, todayDayName, tomorrowDayName)
    : menuSecondType(data, todayDate, tomorrowDate);
};

const menuFirstType = (data, todayDayName, tomorrowDayName) => {
  let modifiedHtml = data.replace(
    /skgd skgdli-menu_ListItem_2-menu_Div_7 composite/g,
    "menuImport"
  );
  let menuData = [];
  if (!data || Object.keys(data).length === 0) {
    return menuData;
  }
  const parser = new DOMParser();
  const doc = parser.parseFromString(modifiedHtml, "text/html");
  const ulElements = doc.querySelectorAll("ul");

  ulElements.forEach((ul) => {
    const liElements = ul.querySelectorAll("div.menuImport");

    liElements.forEach((li) => {
      menuData.push(li.textContent.trim());
    });
  });
  console.log(menuData);
  const canteenMenu = {
    monday: menuData[0],
    tuesday: menuData[1],
    wednesday: menuData[2],
    thursday: menuData[3],
    friday: menuData[4],
  };
  const menu1 = canteenMenu[todayDayName.toLowerCase()];
  const menu2 = canteenMenu[tomorrowDayName.toLowerCase()];
  let updatedMenuData = [];
  updatedMenuData.push(sanitizeMenu(menu1));
  updatedMenuData.push(sanitizeMenu(menu2));
  return updatedMenuData;
};

const sanitizeMenu = (input) => {
  let menu = input;
  if (menu) {
    menu = menu.replace(/\n/g, "<br />");
    return menu;
  }
  return "";
};

const menuSecondType = (html, todayDate, tomorrowDate) => {
  let menuData = [];
  if (!html) {
    return menuData;
  }
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const rows = doc.querySelectorAll("tr");
  let counter = 0;
  for (const row of rows) {
    if (counter > 1) {
      const cols = row.querySelectorAll("td");
      const date = sanitizeDate(cols[0]);
      if (date === todayDate || date === tomorrowDate) {
        const menu = cols[4].innerHTML;
        menuData.push(cols[4].innerHTML);
      }
    }
    counter++;
  }
  return menuData;
};

const sanitizeDate = (input) => {
  let date = input.innerText;
  if (date) {
    date = date
      .replace(/ /g, "")
      .replace(/Pondelok/g, "")
      .replace(/Utorok/g, "")
      .replace(/Streda/g, "")
      .replace(/Štvrtok/g, "")
      .replace(/Piatok/g, "");
    return date;
  }
  return false;
};
