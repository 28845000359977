// import ReactDOM from 'react-dom/client'
import { createRoot } from "react-dom/client";
import { Module } from "../../components/module";

import {
  updateActiveBlocks,
  updateSingleBlock,
} from "../../api/localStorage/updateActiveBlocks";

export const blockScheduler = () => {
  const updateInSeconds = 1 * 1000;
  const blocks = JSON.parse(localStorage.getItem("blocks"));
  const activeBlocks = JSON.parse(localStorage.getItem("active_blocks"));
  setInterval(() => {
    for (const blockId of Object.keys(activeBlocks)) {
      const now = Math.round(Date.now() / 1000);
      const activeBlock = activeBlocks[blockId];
      const { moduleId, timestamp } = activeBlock;
      if (activeBlock !== false) {
        console.log("now", now);
        console.log("activeBlock", activeBlock);
      }
      if (timestamp) {
        if (timestamp === now) {
          // change module in the block
          const newModuleId = updateSingleBlock(
            activeBlocks,
            blocks,
            blockId,
            moduleId
          );
          let data = {};
          data["module"] = blocks[blockId].modules[newModuleId];
          data["block"] = blocks[blockId];
          const changed = Module(data);
          // const root = ReactDOM.createRoot(document.getElementById(blockId))
          // const root = createRoot(document.getElementById(blockId))
          // root.render(changed)

          // ReactDOM.render(changed, document.getElementById(blockId))

          // const root = ReactDOM.createRoot(document.getElementById(blockId))
          // root.render(changed)

          const container = document.getElementById(blockId);
          const root = createRoot(container); // createRoot(container!) if you use TypeScript
          root.render(changed);
        }
      }
    }
  }, updateInSeconds);
};
