import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { screenData } from "../api/getScreen";
import { updateScreen } from "../api/updateScreen";
import { secondsFromTimeString, documentOnSnapshot } from "../helper/common";

export const Screen = () => {
  const css = { width: "1920px", height: "1080px", border: "0" };
  const { screenUrl } = useParams();
  const [currentPresentationUrl, setCurrentPresentationUrl] = useState();
  const [screenCss, setScreenCss] = useState(css);
  const [updateScrDuration, setUpdateScrDuration] = useState(86400);

  console.log("screenCss:: ", screenCss);
  // setInterval(() => {
  //   window.location.reload()
  // }, 86400)
  useEffect(() => {
    const interval = setInterval(
      () => window.location.reload(),
      updateScrDuration
    );

    return () => clearInterval(interval);
  }, [updateScrDuration]);
  useEffect(() => {
    const fetchScreenData = async () => {
      try {
        const screen = await screenData(screenUrl);
        if (!screen) {
          return screen;
        }
        const screenId = screen.id;

        const schedulerActive = screen.schedulerActive;
        // listen to screen refresh from admin
        await documentOnSnapshot("screen", screenId);
        let screenCss = screen.css;
        if (Object.hasOwnProperty.call(screenCss, "height")) {
          let cssHeight = screenCss.height;
          cssHeight = cssHeight.replace("px", "");
          screenCss.height = Number(cssHeight) - 4 + "px";
        }
        setScreenCss(screenCss);
        const { lastPresentationId, presentations, screenScheduler } = screen;

        const data = await findPresentation(
          presentations,
          lastPresentationId,
          schedulerActive,
          screenScheduler
        );
        const { currentPresentation, nextPresentation } = data;
        // let duration = currentPresentation.duration
        setUpdateScrDuration(currentPresentation.duration);

        const presentationUrl =
          process.env.REACT_APP_URL +
          `/presentation/${currentPresentation.url}`;
        setCurrentPresentationUrl(presentationUrl);
        // api call to set next
        if (!schedulerActive) {
          await updateScreen(nextPresentation, screenId);
        } else {
          // if is it scheduled presentation, set duration to 24 hour
          // reload current presentation
          // setUpdateScrDuration(864000)
          // duration = 86400
          await updateScreen(currentPresentation, screenId);
        }
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
      }
    };
    if (screenUrl) {
      fetchScreenData();
    }
  }, [screenUrl]);

  const findPresentation = async (
    presentationsData,
    lastPresentationId,
    schedulerActive,
    screenScheduler
  ) => {
    const AllPresentations = presentationsData.arrayValue.values;
    let nextPresentation = {};
    let currentPresentation = {};
    let isKnown = false;
    let index = 0;
    let counter = 0;
    const count = Object.keys(AllPresentations).length;
    for (const key of Object.keys(AllPresentations)) {
      counter++;
      const presentation = AllPresentations[key];
      const presentationId = await getPresentationId(presentation);
      if (presentationId === lastPresentationId) {
        isKnown = true;
        index = counter;
        currentPresentation = await preparePresentationObject(presentation);
      }
    }
    // if there is not presentation, set the first one, the second as next one
    if (!isKnown) {
      // if is scheduler, try to find presentation in presentation collection
      const AllScreenScheduler = screenScheduler?.arrayValue?.values;
      if (schedulerActive && AllScreenScheduler !== undefined) {
        for (const key of Object.keys(AllScreenScheduler)) {
          const scheduledPresentation = AllScreenScheduler[key];
          const presId =
            scheduledPresentation.mapValue.fields.presentation_id.integerValue;
          if (presId === lastPresentationId) {
            currentPresentation = await preparePresentationObject(
              scheduledPresentation
            );
            nextPresentation = currentPresentation;
          }
        }
      } else {
        currentPresentation = await preparePresentationObject(
          AllPresentations[0]
        );
        nextPresentation = await preparePresentationObject(AllPresentations[1]);
      }
      return { currentPresentation, nextPresentation };
    }
    // set next presentation
    if (index === count) {
      // if is last presentation, next one must be the first presentation
      nextPresentation = await preparePresentationObject(AllPresentations[0]);
    } else {
      nextPresentation = await preparePresentationObject(
        AllPresentations[index]
      );
    }
    return { currentPresentation, nextPresentation };
  };

  const getPresentationId = async (presentation) => {
    const presentationIdObj = presentation?.mapValue?.fields?.presentation_id;
    if (presentationIdObj) {
      if (Object.hasOwnProperty.call(presentationIdObj, "integerValue")) {
        return presentationIdObj.integerValue;
      } else {
        return presentationIdObj.stringValue;
      }
    }
  };

  const preparePresentationObject = async (presentation) => {
    const presentationDuration =
      presentation?.mapValue?.fields?.presentation_duration?.stringValue;
    const duration = presentationDuration
      ? secondsFromTimeString(presentationDuration) * 1000
      : 0;
    return {
      id: await getPresentationId(presentation),
      url: presentation?.mapValue?.fields?.presentation_url?.stringValue,
      duration: duration,
    };
  };
  return (
    <>
      <iframe
        title="datavision"
        style={screenCss}
        height="100%"
        width="100%"
        scrolling="no"
        src={currentPresentationUrl}
      ></iframe>
    </>
  );
};
