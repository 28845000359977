// Sentry
import * as Sentry from "@sentry/react";
import app from "../firebase/config";
import {
  doc,
  getFirestore,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
const db = getFirestore(app);
export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const fetchData = async (collection, id) => {
  try {
    const docRef = doc(db, collection, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return false;
    }
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const updateReload = async (collection, id) => {
  try {
    const docRef = doc(db, collection, id);
    await updateDoc(docRef, {
      reload: false,
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};

/**
 *
 * @param {*} secondsValue
 * @returns
 */
export const prepareTimestampFromSeconds = (secondsValue) => {
  return Math.round(Date.now() / 1000) + secondsValue;
};

/**
 *
 * @param {*} durationString
 * @returns
 */
export const secondsFromTimeString = (durationString) => {
  // if (durationString) {
  //   let [hours, minutes, seconds] = durationString.split(":");
  //   if (!seconds) {
  //     seconds = 0;
  //   }
  //   return hours * 60 * 60 + +minutes * 60 + +seconds;
  // }
  let [hours, minutes, seconds] = durationString.split(":");

  // If seconds are missing, set them to "00"
  if (seconds === undefined) {
    seconds = "00";
  }

  // Convert hours, minutes, and seconds to integers
  hours = parseInt(hours, 10);
  minutes = parseInt(minutes, 10);
  seconds = parseInt(seconds, 10);

  // Calculate the total seconds
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;

  // Return the total seconds
  return totalSeconds;
};

export const documentOnSnapshot = async (collection, id) => {
  if (id) {
    console.log("snapshot on collection " + collection + " id: " + id);
    await onSnapshot(doc(db, collection, id), async (doc) => {
      if (collection === "screen") {
        const document = doc._document;
        if (document) {
          if ("data" in document) {
            // if (document.hasOwnProperty('data')) {
            const data = document.data.value.mapValue.fields;
            if ("reload" in data) {
              // if (data.hasOwnProperty('reload')) {
              if (data.reload.booleanValue === true) {
                await updateReload(collection, id);
                // update reload on the presentation to false
                window.location.reload(false);
              }
            }
            console.log("Current data: ", data);
          }
        }
      }
    });
  }
};

/**
 *
 * @param {*} min
 * @param {*} max
 * @returns
 */
export const generateRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 *
 * @param {*} jsonData
 * @returns
 */
export const parseJsonData = (jsonData) => {
  if (jsonData) {
    return JSON.parse(jsonData);
  }
};

/**
 *
 * @param {*} strAccents
 * @returns
 */
export const removeAccents = (strAccents) => {
  var strAccents = strAccents.split("");
  var strAccentsOut = new Array();
  var strAccentsLen = strAccents.length;
  var accents =
    "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëÇçðÐÌÍÎÏìíîïÙÚÛÜùúûüůŮÑñŠšŸÿýŽžČčŤťŽžůŮ";
  var accentsOut =
    "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeCcdDIIIIiiiiUUUUuuuuuUNnSsYyyZzCcTtZz";
  for (var y = 0; y < strAccentsLen; y++) {
    if (accents.indexOf(strAccents[y]) != -1) {
      strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
    } else strAccentsOut[y] = strAccents[y];
  }
  strAccentsOut = strAccentsOut.join("");

  return strAccentsOut;
};

/**
 *
 * @param {*} imagePath
 * @returns
 */
export const prepareImage = (imagePath) => {
  if (imagePath.includes("http")) {
    return imagePath;
  }
  if (!imagePath) {
    return false;
  }
  return process.env.REACT_APP_SYMFONY_HOST + imagePath;
};
